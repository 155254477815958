.design-four-page-three-body{
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: white;
    position: fixed;
    top:0;
}


    .design-four-page-three-left-body{
        width: 30%;
        vertical-align: top;
        height: 100vh;
        background-color: white;
        display: inline-block;
        position: relative;
        overflow-y: auto;
        z-index: 2;
    }
    
    .design-four-page-three-right-body{
        width: 70%;
        vertical-align: top;
        height: 100vh;
        background-color: #f7f7f7;
        display: inline-block;
        position: relative;
        z-index: 2;
        overflow-y: scroll;
    }
    
    .design-four-page-three-left-cityexplorer{
        width: 95%;
        margin-left: 2.5%;
        margin-right: 0;
        margin-top: 100px;
        padding-top: 75px;
        height: 50px;
        position: relative;
        vertical-align: top;
        text-align: center;
    }
    
    .design-four-page-three-left-cityexplorer-logo{
        width: 40px;
        height: 40px;
        margin-top: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        max-width: 15%;
    }
    .design-four-page-three-left-cityexplorer-logo-image{
        width: 100%;
        height: auto;
        }
    
    .design-four-page-three-left-cityexplorer-mapsuntold{
        height: 40px;
        margin-top: 5px;
        padding-left: 3px;
        padding-right: 10px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 14px;
        font-weight: 500;
        color: lightgray;
        max-width: 25%;
    
    
    }
    .design-four-page-three-left-cityexplorer-mapsuntold-text{
        margin-top: 20px;
        transform: translateY(-50%);
    }
    .design-four-page-three-left-cityexplorer-line{
        position: relative;
        display: inline-block;
        width: 2.5px;
        background-color: lightgray;
        height: 35px;
        margin-top: 7px;
        border-top: 3px;
        border-radius: 1.5px;
    }
    .design-four-page-three-left-cityexplorer-city{
        height: 40px;
        max-width: 50%;
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .design-four-page-three-left-cityexplorer-city-text{
        margin-top: 20px;
        transform: translateY(-50%);
        font-family: montserrat;
        font-size: 14px;
        color: #264f73;
        font-weight: 600;
    }
    .design-four-page-three-left-header{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 600;
        padding: 20px 50px;
        line-height: 40px;
    }
    
    
    .design-four-page-three-left-subheader{
        font-family: montserrat;
        font-size: 14px;
        font-weight: 400;
        color: grey;
        font-size: 15px;
        padding: 0 50px 85px;
        line-height: 22.5px;
    }
    


    .design-four-page-three-right-first-container{
        padding-top: 100px;
        font-size: 0;
    }
    .design-four-page-three-right-category-part{
        font-size: 0;
    }
    .design-four-page-three-right-category-part-header{
        font-size: 22.5px;
        font-family: montserrat;
        font-weight: 500;
         margin-bottom: 5px;
         margin-top: 30px;
    }
    .design-four-page-three-right-category-part-subheader{
        font-size: 15px;
        font-family: montserrat;
        margin-bottom: 5px;
    }

    .design-four-page-three-right-category-part-inputbar{
        font-size: 15px;
        font-family: montserrat;
        height: 50px;
        padding-left: 10px;
        max-width: 95%;
        width: 700px;
        border-style: none;
        border-width: 0;
        cursor: text;

    }

    textarea:focus, input:focus{
        outline: none;
    }

    .design-four-page-three-right-categories-container{
        position: relative;
        width: 87.5%;
        padding-left: 10%;
        margin-top: 0;
        padding-bottom: 100px;
        }

        .autocompletesuggestions{
            font-size: 12.5px;
            font-family: montserrat;
            font-weight: 400;
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            cursor: pointer;
        }


.design-four-page-three-right-category-part-suggestions-container{
    background-color: white;
    max-width: 95%;
        width: 700px;
        padding-left: 10px;
        padding-right: 2px;
}
:hover.autocompletesuggestions{
    font-weight: bold;
}

.design-four-page-three-right-favorite-part{
    display: inline-block;
font-size: 0;
background-color: red;
border-radius: 0;
margin-top: 10px;
margin-right: 20px;
}

.design-four-page-three-right-favorite-text{
    display: inline-block;
    font-size: 15px;
    font-family: montserrat;
    font-weight: 500;
    color: white;
    padding: 5px 0 5px 10px;
}

.design-four-page-three-right-favorite-kruis{
    display: inline-block;
    position: relative;
    /* background-color: peru; */
    width: 20px;
    height: 13px;
    margin-top: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.design-four-page-three-right-favorites-container{
    font-size: 0;   
    display: inline-block;
    }

.design-four-page-three-right-favorite-kruis-image{
    position: absolute;
    margin-top: -6px;
    margin-left: -10px;
    width: 27.5px;
    height: 27.5px;
    cursor: pointer;

}



@media screen and (max-width: 1024px) {

    .design-four-page-three-body{
        width: 100%;
        background-color: white;
        position: fixed;
        overflow-y: scroll;
        top:0;
    }

    .design-four-page-three-left-body{
        width: 100%;
        height: fit-content;
        display: block;
        padding-bottom: 50px;
    }

    .design-four-page-three-left-subheader{
        padding: 0 50px 0;
    }

    .design-four-page-three-right-body{
        width: 100%;
        display: block;
        height: auto;
    }

    .design-four-page-three-right-first-container{
        padding-top: 0;
        font-size: 0;
    }

  }