.design-four-progressbar{
    bottom: 0;
    width: 100%;
    height: 75px;
    z-index: 12;
    position: fixed;
    background-color: white;
    box-shadow: 0 0 10px 0 #888888;
    text-align: right;
    }

    .design-four-progressline{
        height: 5px;
        background-color: red;
        z-index: 13;
        transition: all 0.2s ease-out;
        border-right: 5px;
        border-radius: 3px;
        margin-top: -5px;
        }

.design-four-backbutton-inactive{
display: none;
}

.design-four-backbutton-transition{
  display: inline-block;
    color: white;
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
    animation: disappear 0.05s ease-in-out;
    transform: scale(0);
    font-family: montserrat;
    font-size: 15px;
    font-weight: 500;
    border-style: solid;
    border-width: 4px;
    border-radius: 0;
    border-color: black;
}
@keyframes disappear {
    0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
}

.design-four-backbutton{
  display: inline-block;
  width: 200px;
  height: 35px;
  cursor: pointer;
  margin-top: 17px;
  margin-right: 10px;
  position: relative;
  border-style: solid;
    border-width: 4px;
    border-radius: 0;
    animation: appear 0.05s ease-in-out;

}
.design-four-backbutton-text{
  color: black;
  text-align: center;
  font-family: montserrat;
  font-weight: 500;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes appear {
    0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }


.design-four-nextbutton{
  display: inline-block;
  background-color: red;
  width: 200px;
  height: 35px;
  cursor: pointer;
  margin-top: 17px;
  margin-right: 10px;
  position: relative;
  border-style: solid;
  border-width: 4px;
  border-radius: 0;
  border-color: red;
}
.design-four-nextbutton-text{
  color: white;
  text-align: center;
  font-family: montserrat;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



@media screen and (max-width: 768px) {
  .design-four-backbutton-transition{
    width: 125px;
  }
    .design-four-nextbutton{
      width: 125px;
    }
    .design-four-backbutton{
      width: 125px;
    }
}
