.design-two-page-four-body{
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: white;
    position: fixed;
    top:0;
}


    .design-two-page-four-left-body{
        width: 30%;
        vertical-align: top;
        height: 100vh;
        background-color: white;
        display: inline-block;
        position: relative;
        overflow-y: auto;
        z-index: 2;
    }
    
    .design-two-page-four-right-body{
        width: 70%;
        vertical-align: top;
        height: 100vh;
        background-color: #f7f7f7;
        display: inline-block;
        position: relative;
        z-index: 2;
        overflow-y: scroll;
    }
    
    .design-two-page-four-left-cityexplorer{
        width: 95%;
        margin-left: 2.5%;
        margin-right: 0;
        margin-top: 100px;
        padding-top: 75px;
        height: 50px;
        position: relative;
        vertical-align: top;
        text-align: center;
    }
    
    .design-two-page-four-left-cityexplorer-logo{
        width: 40px;
        height: 40px;
        margin-top: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        max-width: 15%;
    }
    .design-two-page-four-left-cityexplorer-logo-image{
        width: 100%;
        height: auto;
        }
    
    .design-two-page-four-left-cityexplorer-mapsuntold{
        height: 40px;
        margin-top: 5px;
        padding-left: 3px;
        padding-right: 10px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 14px;
        font-weight: 500;
        color: lightgray;
        max-width: 25%;
    
    
    }
    .design-two-page-four-left-cityexplorer-mapsuntold-text{
        margin-top: 20px;
        transform: translateY(-50%);
    }
    .design-two-page-four-left-cityexplorer-line{
        position: relative;
        display: inline-block;
        width: 2.5px;
        background-color: lightgray;
        height: 35px;
        margin-top: 7px;
        border-top: 3px;
        border-radius: 1.5px;
    }
    .design-two-page-four-left-cityexplorer-city{
        height: 40px;
        max-width: 50%;
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .design-two-page-four-left-cityexplorer-city-text{
        margin-top: 20px;
        transform: translateY(-50%);
        font-family: montserrat;
        font-size: 14px;
        color: #264f73;
        font-weight: 600;
    }
    .design-two-page-four-left-header{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 600;
        padding: 20px 50px;
        line-height: 40px;
    }
    
    
    .design-two-page-four-left-subheader{
        font-family: montserrat;
        font-size: 14px;
        font-weight: 400;
        color: grey;
        font-size: 15px;
        padding: 0 50px 85px;
        line-height: 22.5px;
    }

    .design-two-page-four-right-first-container{
        font-size: 0;
        padding-top: 100px;
    }

    .design-two-page-four-right-recommendations-container{
            position: relative;
            width: 90%;
            min-height: 70vh;
            padding-left: 10%;
        }

    .design-two-page-four-subcategories-recos-container{
        display: inline-block;
        margin: 0;
        }

    .design-two-page-four-subcategories-recos-parentcategory{
        font-size: 30px; 
        margin-top: 25px;
        margin-bottom: 15px;
        font-family: montserrat;
        font-weight: 500;
    }

    .design-two-page-four-subcategories-recos-category-description{
        font-size: 20px;
        margin-bottom: 5px;
        font-family: montserrat;
        font-weight: 400;
    }


    .design-two-page-four-right-subcategory-not-selected{
        border-style: solid;
        border-color: #264f73;
        border-radius: 0;
        color: #264f73;
        font-size: 15px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-right: 5px;  
        cursor: pointer; 
        margin-bottom: 10px;
        font-family: montserrat;
        font-weight: 500;
    }
    .design-two-page-four-right-subcategory-selected{
        border-style: solid;
        border-color: #264f73;
        border-radius: 0;
        background-color: #264f73;
        color: white;
        font-size: 15px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-right: 5px;  
        cursor: pointer; 
        margin-bottom: 10px;
        font-family: montserrat;
        font-weight: 500;
    }

    .design-two-page-four-recos-container{
        display: block;
        font-size: 15px;
        white-space: nowrap;
        overflow-x: auto;
        }

    .design-two-page-four-reco-card{
        height: 280px;
        width: 240px;
        border-radius: 0;
        margin-right: 10px;
        margin-bottom: 5px;
        box-shadow: 3px 2px 3px #ddd;
        background-color: white;
    }
    .design-two-page-four-arrows-container{
        display: block;
        margin-bottom: 10px;
    }
    .design-two-page-four-arrow-container{
        cursor: pointer ;
        pointer-events: auto; 
        width: 25px;
        height: 25px;
        border-style: solid;
        border-width: 3px;
        margin-right: 5px;
        display: inline-block;
        border-radius: 50%;
        border-color: #264f73;
    }
    .design-two-page-four-arrow-right{
        border: solid #264f73;
        border-width: 0 3px 3px 0;
        display: inline-block;
        margin-top: 7px;
        margin-left: 5px;
        padding: 4px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        /* pointer-events: none;  */
    }
    .design-two-page-four-arrow-left{
        border: solid #264f73;
        border-width: 0 3px 3px 0;
        display: inline-block;
        margin-top: 7px;
        margin-left: 9px;
        padding: 4px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        /* pointer-events: none;  */
    }

    @media (hover: hover) {
    .design-two-page-four-arrow-container:hover{
        background-color: #264f73;
    }
    .design-two-page-four-arrow-container:hover{
        background-color: #264f73;
    }
    }


    .design-two-page-four-reco-card-image-outer-div{
        width: 100%;
        height: 140px;
    }
    .design-two-page-four-reco-card-image-div{
        width: 100%;
        height: 140px;
    }
    .design-two-page-four-reco-card-score{
        width: 45px;
        height: 30px;
        position: relative;
        background-color: red;
        margin-top: -140px;
        margin-bottom: 110px;
        margin-left: 175px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        text-align: center;
        font-family: montserrat;
        font-weight: 500;
        font-size: 13px;
        color: white;
        padding-top: 10px;
    }

    .design-two-page-four-reco-card-top{
        width: fit-content;
        height: 30px;
        position: relative;
        background-color: red;
        margin-top: -140px;
        margin-bottom: 110px;
        margin-left: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .design-two-page-four-reco-card-top-text{
        text-align: center;
        font-family: montserrat;
        font-weight: 500;
        font-size: 16px;
        color: white;
        padding-top: 5px;
    }


    .design-two-page-four-reco-card-title{
        width: 220px;
        margin-left: 10px;
        margin-top: 10px;        
        font-family: montserrat;
        font-size: 13px;
        font-weight: 600;
        white-space: normal;
        height: 40px;
}
    .design-two-page-four-reco-card-category{
        width: 220px;
        margin-left: 12px;    
        font-family: montserrat;
        font-size: 11px;
        font-weight: 500;
        white-space: normal;
        margin-top: 10px;
        height: 20px;
}
        .design-two-page-four-reco-card-website{
        margin-left: 12px;    
        font-family: montserrat;
        font-size: 11px;
        font-weight: 700;
        white-space: normal;
        margin-top: 35px;
        height: 20px;
        color: #264f73;
        text-decoration: underline;
        width: fit-content;
}

    .design-two-page-four-reco-card-address{
        width: 35px;
        height: 35px;
        margin-top: -39px;
        background-color: #264f73;
        border-radius: 50%;
        margin-left: 180px;
}
    .design-two-page-four-reco-card-address-image{
        width: 35px;
        height: 35px;
}

























.design-two-page-four-reco-card-list{
    height: 270px;
    width: 240px;
    border-radius: 0;
    margin-right: 10px;
    margin-bottom: 5px;
    box-shadow: 3px 2px 3px #ddd;
    padding-top: 10px;
    background-color: red;
}

.design-two-page-four-reco-card-image-outer-div-list{
    width: 90%;
    height: 130px;
    margin-left: 5%;
}
.design-two-page-four-reco-card-image-div-list{
    width: 100%;
    height: 130px;
}

.design-two-page-four-reco-card-content-list{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    margin-top: -10px;
    height: 130px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.design-two-page-four-reco-card-score-list{
    width: 45px;
    height: 30px;
    position: relative;
    background-color: red;
    margin-top: -140px;
    margin-bottom: 110px;
    margin-left: 175px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    font-family: montserrat;
    font-weight: 500;
    font-size: 13px;
    color: white;
    padding-top: 10px;
}

.design-two-page-four-reco-card-top-list{
    width: fit-content;
    height: 30px;
    position: relative;
    background-color: red;
    margin-top: -130px;
    margin-bottom: 100px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;

}
.design-two-page-four-reco-card-top-text-list{
    text-align: center;
    font-family: montserrat;
    font-weight: 500;
    font-size: 16px;
    color: white;
    padding-top: 5px;
}


.design-two-page-four-reco-card-title-list{
    width: 220px;
    margin-left: 10px;
    padding-top: 10px;        
    font-family: montserrat;
    font-size: 13px;
    font-weight: 600;
    white-space: normal;
    height: 40px;
}
.design-two-page-four-reco-card-category-list{
    width: 220px;
    margin-left: 12px;    
    font-family: montserrat;
    font-size: 11px;
    font-weight: 500;
    white-space: normal;
    margin-top: 10px;
    height: 20px;
}
    .design-two-page-four-reco-card-website-list{
    margin-left: 12px;    
    font-family: montserrat;
    font-size: 11px;
    font-weight: 700;
    white-space: normal;
    margin-top: 30px;
    height: 20px;
    color: #264f73;
    text-decoration: underline;
    width: fit-content;
}

.design-two-page-four-reco-card-address-list{
    width: 35px;
    height: 35px;
    margin-top: -44px;
    background-color: #264f73;
    border-radius: 50%;
    margin-left: 170px;
}
.design-two-page-four-reco-card-address-image-list{
    width: 35px;
    height: 35px;
}



.design-two-page-four-lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .design-two-page-four-lds-dual-ring:after {
    content: " ";
    display: block;
    width: 75px;
    height: 75px;
    margin-left: calc(30vw - 37.5px);
    margin-top: calc(30vh - 37.5px);
    border-radius: 50%;
    border: 7px solid gray;
    border-color: gray transparent gray transparent;
    animation: design-one-page-four-lds-dual-ring 1.2s linear infinite;
  }
  @keyframes design-one-page-four-lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .design-two-page-four-feedback-container{
    width: 100%;
  }
  .design-two-page-four-feedback-subcontainer{
    width: fit-content;
    height: 75px;
    font-size: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .design-two-page-four-feedback-feedbackbuttons{
    height: 75px;
    width: 75px;
    display: inline-block;
  }
  .design-two-page-four-feedback-feedbackbuttons-image{
height: 100%;
width: 100%;
pointer-events: none;
  }
  .design-two-page-four-feedback-textcontainer{
    height: 400px;
    width: 500px;
    animation: design-two-page-four-feedbacktext 0.2s linear;
  }
  @keyframes design-two-page-four-feedbacktext {
    0% {
        opacity: 0;
      }
      100% {
        opacity: 100;
      }
  }
  .design-two-page-four-feedback-feedbackbuttons-image-clicked{
    height: 200%;
    width: 100%;
    pointer-events: none;
    animation: design-two-page-four-feedbacksmiley 2.5s ease-in-out;
}
@keyframes design-two-page-four-feedbacksmiley {
    0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  }

  
  .design-two-page-four-feedback-textcontainer-input{
    width: 350px;
    padding: 0;
    outline: none;
    height: 100%;
    resize: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    box-shadow: 3px 2px 3px #ddd;
    border-radius: 0;
    border: none;
    font-family: montserrat;
    font-size: 16px;
  }

  .design-two-page-four-feedback-textcontainer-submit-button{
    font-family: montserrat;
    font-size: 15px;
    border: 6px;
    border-style: solid;
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 4px;
    cursor: pointer;
  }

  .design-two-page-four-feedback-thanks{
font-family: montserrat;
  }

  .design-two-page-four-feedback-question{
    font-family: montserrat;
    }


  @media screen and (max-width: 1100px) {

    .design-two-page-four-feedback-subcontainer{
        width: 100%;
    }

    .design-two-page-four-feedback-textcontainer{
        width: 90%;
        margin-left: 5%;
    }

    .design-two-page-four-feedback-textcontainer-input{
        width: 90%;
        margin-left: 5%;
  }}




@media screen and (max-width: 1024px) {
    .design-two-page-four-body{
        width: 100%;
        background-color: white;
        position: fixed;
        overflow-y: scroll;
        top:0;
    }
    .design-two-page-four-left-body{
        width: 100%;
        height: fit-content;
        display: block;
        padding-bottom: 50px;
    }

    .design-two-page-four-left-subheader{
        padding: 0 50px 0;
    }

    .design-two-page-four-right-body{
        width: 100%;
        display: block;
        height: auto;
    }
    .design-two-page-four-lds-dual-ring:after {
        margin-left: calc(40vw - 37.5px);
    }
    .design-two-page-four-right-first-container{
        padding-top: 0;
    }
  }



