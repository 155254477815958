    @font-face {
        font-family: "apercu";
        src: local("apercu_bold_italic_pro.otf"),
         url("./fonts/apercu_bold_italic_pro.otf") format("opentype");
        font-weight: bold italic;
        }

    @font-face {
        font-family: "apercu";
        src: local("apercu_bold_pro.otf"),
         url("./fonts/apercu_bold_pro.otf") format("opentype");
        font-weight: bold;
        }

    @font-face {
        font-family: "apercu";
        src: local("apercu_regular_italic_pro.otf"),
         url("./fonts/apercu_regular_italic_pro.otf") format("opentype");
        font-weight: italic;
        }

    @font-face {
        font-family: "apercu";
        src: local("apercu_regular_pro.otf"),
         url("./fonts/apercu_regular_pro.otf") format("opentype");
        font-weight: normal;
        }

    @font-face {
        font-family: "apercu bold italic";
        src: local("apercu_bold_italic_pro.otf"),
         url("./fonts/apercu_bold_italic_pro.otf") format("opentype");
        font-weight: bold italic;
        }

    @font-face {
        font-family: "apercu bold";
        src: local("apercu_bold_pro.otf"),
         url("./fonts/apercu_bold_pro.otf") format("opentype");
        font-weight: bold;
        }

    @font-face {
        font-family: "apercu italic";
        src: local("apercu_regular_italic_pro.otf"),
         url("./fonts/apercu_regular_italic_pro.otf") format("opentype");
        font-weight: italic;
        }

    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-Bold.ttf"),
         url("./fonts/BorealWeb-Bold.ttf") format("truetype");
        font-weight: bold;
        }

    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-BoldItalic.ttf"),
         url("./fonts/BorealWeb-BoldItalic.ttf") format("truetype");
        font-weight: bold italic;
        }

    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-Italic.ttf"),
         url("./fonts/BorealWeb-Italic.ttf") format("truetype");
        font-weight: italic;
        }

    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-Light.ttf"),
         url("./fonts/BorealWeb-Light.ttf") format("truetype");
        font-weight: lighter;
        }

    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-LightItalic.ttf"),
         url("./fonts/BorealWeb-LightItalic.ttf") format("truetype");
        font-weight: lighter italic;
        }
        
    @font-face {
        font-family: "BorealWeb";
        src: local("BorealWeb-Regular.ttf"),
         url("./fonts/BorealWeb-Regular.ttf") format("truetype");
        font-weight: normal;
        }

    @font-face {
        font-family: "BorealWeb bold";
        src: local("BorealWeb-Bold.ttf"),
         url("./fonts/BorealWeb-Bold.ttf") format("truetype");
        font-weight: bold;
        }

    @font-face {
        font-family: "BorealWeb bold italic";
        src: local("BorealWeb-BoldItalic.ttf"),
         url("./fonts/BorealWeb-BoldItalic.ttf") format("truetype");
        font-weight: bold italic;
        }

    @font-face {
        font-family: "BorealWeb italic";
        src: local("BorealWeb-Italic.ttf"),
         url("./fonts/BorealWeb-Italic.ttf") format("truetype");
        font-weight: italic;
        }

    @font-face {
        font-family: "BorealWeb lighter";
        src: local("BorealWeb-Light.ttf"),
         url("./fonts/BorealWeb-Light.ttf") format("truetype");
        font-weight: lighter;
        }

    @font-face {
        font-family: "BorealWeb lighter italic";
        src: local("BorealWeb-LightItalic.ttf"),
         url("./fonts/BorealWeb-LightItalic.ttf") format("truetype");
        font-weight: lighter italic;
        }
        
    @font-face {
        font-family: "FoundersGroteskXCond-SmBd";
        src: local("FoundersGroteskXCond-SmBd.otf"),
         url("./fonts/FoundersGroteskXCond-SmBd.otf") format("opentype");
        font-weight: normal;
        }
        
    @font-face {
        font-family: "Gilroy-ExtraBold";
        src: local("Gilroy-ExtraBold.otf"),
         url("./fonts/Gilroy-ExtraBold.otf") format("opentype");
        font-weight: normal;
        }