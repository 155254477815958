.home-container {
    margin: 0;
    text-align: center;
}


.loginscreen-top-buttons-container {
width: 100%;    
position: fixed;
top: 0;
z-index: 10;
}

.loginscreen-top-buttons {
height: 40px;
width: 33.3333333333%;
background-color: white;
display: inline-block;
text-align: center;
position: relative;
font-family: montserrat;
font-weight: 400;
font-size: 20px;
padding-top: 20px;
cursor: pointer;
}
.loginscreen-top-buttons:hover {
    background-color: rgb(163, 163, 163);
    cursor: pointer;
}


.loginscreen-animation{
width: 100%;
height: 100vh;
background-color: rgb(34, 34, 34);
}

.loginscreen-animation-inner-div-image{
position: absolute;
width: 250px;
height: 250px;
top: 50%;
left: 50%;
margin-top: -125px;    
margin-left: -125px;    
animation: rotateimage 5s linear infinite;
}
@keyframes rotateimage {
    0% {
        transform: rotate(0deg);
            }
    100% {
        transform: rotate(180deg);
    }
  }

.verifying-page{
    width: 100%;
    height: 100vh;
    background-color: rgb(34, 34, 34);
    }
.verifying-page-text{
    position: absolute;
    width: 100%;
    height: 250px;
    top: 50%;
    margin-top: -125px;    
    font-size: 30px;
    font-family: montserrat;
    color: white;
    text-align: center;
    letter-spacing: 10px;
}