.login-recommendations-container {
    padding-top: 60px;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-color: rgb(34, 34, 34);
   }
       .login-recommendations-categories-container{
        position: absolute;
        text-align: center;
        width: 80%;
        left: 10%;
        margin-top: 20px;
    }
        .login-recommendations-categories{
        display: inline-block;
        background-color: rgb(213, 213, 213);
        font-size: 20px;
        font-family: montserrat;
        padding-top: 8px ;
        padding-bottom: 8px ;
        padding-left: 25px;
        padding-right: 25px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        cursor: pointer;
    }
    .login-recommendations-categories:hover{
        background-color: rgb(163, 163, 163);
    }

    .login-recommendations-categories-all-locations-container{
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-location-container{
        height: 250px;
        width: 200px;
        background-color: white;
        display: inline-block;
        vertical-align: top;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 20px;
        font-family: montserrat;
    }

    .login-recommendations-categories-excluded-locations-container{
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }

    .login-recommendations-categories-excluded-locations-excluded-locations-list-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-excluded-locations-all-locations-list-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }

    .login-recommendations-categories-location-text{
        margin-top: 2.5px;
    }
    .login-recommendations-categories-location-button{
        margin-top: 5px;
        background-color: rgb(34, 34, 34);
        width: 140px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 4px;
        padding-right: 4px;
        margin-left: 28px;
        border-radius: 4px;
        color: white;
        font-weight: 400;
        border-style: solid;
        border-color: rgb(34, 34, 34);
        border-width: 2px;
        cursor: pointer;
    }
    .login-recommendations-categories-location-button:hover{
        color: rgb(34, 34, 34);
        background-color: white;
        font-weight: 500;
        cursor: pointer;
    }

    .login-recommendations-categories-number-of-recommendations-container{
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-number-of-recommendations-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }
    .login-recommendations-categories-number-of-recommendations-number{
        font-size: 60px;
        font-family: montserrat;
        color: white;
        text-align: center;
        margin-top: 30px;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-number-of-recommendations-numbers-container{
        white-space: nowrap;
        overflow-x: auto;        
        margin-top: 30px;
        border-radius: 5px;    
    }
    .login-recommendations-categories-number-of-recommendations-numbers-outer-divs{
        background-color: white;   
        display: inline-block;
        }
    .login-recommendations-categories-number-of-recommendations-number-divs{
        font-size: 20px;
        font-family: montserrat;
        color: rgb(34, 34, 34);
        text-align: center;
        letter-spacing: 0;
        background-color: white;
        font-weight: 500;
        width: 50px;
        padding-top: 30px;   
        padding-bottom: 30px;   
        cursor: pointer;
        }
    .login-recommendations-categories-number-of-recommendations-number-divs:hover{
        background-color: rgb(163, 163, 163);
        border-radius: 7px;
    }


    .login-recommendations-categories-number-of-minimal-recommendations-container{
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-number-of-minimal-recommendations-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }
    .login-recommendations-categories-number-of-minimal-recommendations-number{
        font-size: 60px;
        font-family: montserrat;
        color: white;
        text-align: center;
        margin-top: 30px;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-number-of-minimal-recommendations-numbers-container{
        white-space: nowrap;
        overflow-x: auto;        
        margin-top: 30px;
        border-radius: 5px;    
    }
    .login-recommendations-categories-number-of-minimal-recommendations-numbers-outer-divs{
        background-color: white;   
        display: inline-block;
        }
    .login-recommendations-categories-number-of-minimal-recommendations-number-divs{
        font-size: 20px;
        font-family: montserrat;
        color: rgb(34, 34, 34);
        text-align: center;
        letter-spacing: 0;
        background-color: white;
        font-weight: 500;
        width: 50px;
        padding-top: 30px;   
        padding-bottom: 30px;   
        cursor: pointer;
        }
    .login-recommendations-categories-number-of-minimal-recommendations-number-divs:hover{
        background-color: rgb(163, 163, 163);
        border-radius: 7px;
    }


    .login-recommendations-categories-image-changes-locations-container {
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-locations-image-changes-locations-list-included-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-locations-image-changes-locations-list-excluded-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }
    .login-recommendations-categories-location-image-changes-container {
        height: 250px;
        width: 200px;
        background-color: white;
        display: inline-block;
        vertical-align: top;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 20px;
        font-family: montserrat;
    }
    .login-recommendations-categories-locations-image-changes-inputbar{
        width: 700px;
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        font-family: montserrat;
        height: 50px;
        padding-left: 10px;
        border-style: solid;
        border-width: 7.5px;
    }

    .login-recommendations-categories-website-changes-locations-container {
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-locations-website-changes-locations-list-included-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-locations-website-changes-locations-list-excluded-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }
    .login-recommendations-categories-location-website-changes-container {
        height: 250px;
        width: 200px;
        background-color: white;
        display: inline-block;
        vertical-align: top;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 20px;
        font-family: montserrat;
    }
    .login-recommendations-categories-locations-website-changes-inputbar{
        width: 700px;
        position: fixed;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        font-family: montserrat;
        height: 50px;
        padding-left: 10px;
        border-style: solid;
        border-width: 7.5px;
    }

    textarea:focus, input:focus{
        outline: none;
    }



    .login-recommendations-categories-top-changes-locations-container {
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-locations-top-changes-locations-list-included-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-locations-top-changes-locations-list-excluded-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }

    .login-recommendations-categories-location-text-top{
        margin-top: 0;
        font-size: 14px;
    }

    .login-recommendations-categories-location-button-top{
        display: inline-block;
        margin-top: 5px;
        background-color: rgb(34, 34, 34);
        width: 70px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 28px;
        border-radius: 4px;
        color: white;
        font-weight: 400;
        border-style: solid;
        border-color: rgb(34, 34, 34);
        border-width: 2px;
        cursor: pointer;
        font-size: 13px;
    }
    .login-recommendations-categories-location-button-top:hover{
        color: rgb(34, 34, 34);
        background-color: white;
        font-weight: 500;
        cursor: pointer;
    }

    .login-recommendations-categories-location-input-top{
        display: inline-block;
        width: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 10px;
        border-radius: 4px;
        border-style: solid;
        border-color: rgb(34, 34, 34);
        color: rgb(34, 34, 34);
        font-family: montserrat;
        font-weight: 700;
        text-align: center;
    }


    .login-recommendations-categories-frames-changes-locations-container {
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-locations-frames-changes-locations-list-included-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-locations-frames-changes-locations-list-excluded-header {
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }
    .login-recommendations-categories-location-text-frames{
        margin-top: 0;
        font-size: 14px;
    }

    .login-recommendations-categories-location-button-frames{
        display: inline-block;
        margin-top: 5px;
        background-color: rgb(34, 34, 34);
        width: 70px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 28px;
        border-radius: 4px;
        color: white;
        font-weight: 400;
        border-style: solid;
        border-color: rgb(34, 34, 34);
        border-width: 2px;
        cursor: pointer;
        font-size: 13px;
    }
    .login-recommendations-categories-location-button-frames:hover{
        color: rgb(34, 34, 34);
        background-color: white;
        font-weight: 500;
        cursor: pointer;
    }

    .login-recommendations-categories-location-input-frames{
        display: inline-block;
        width: 40px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 10px;
        border-radius: 4px;
        border-style: solid;
        border-color: rgb(34, 34, 34);
        color: rgb(34, 34, 34);
        font-family: montserrat;
        font-weight: 700;
        text-align: center;
    }


    .login-recommendations-categories-categories-container{
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-top: 220px;
    }
    .login-recommendations-categories-categories-overview-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
    }
    .login-recommendations-categories-categories-header{
        font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-top: 100px;
    }

    

    .login-recommendations-categories-categories-parentcategory-container{
        text-align: left;
        display: block;
        margin-bottom: 30px;
        }
    .login-recommendations-categories-categories-parentcategory{
        text-align: center;
            font-size: 20px;
            font-family: montserrat;
            color: black;
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: 600;
            width: fit-content; 
            border-radius: 10px;
            margin-bottom: 10px;
            margin-right: 5px;
    }
    .login-recommendations-categories-categories-subcategory-container{
        text-align: center;
        display: inline-block;
        vertical-align: top;
        }
        .login-recommendations-categories-categories-subcategory{
            text-align: center;
            font-size: 15px;
            font-family: montserrat;
            color: white;
            background-color: rgb(34, 34, 34);;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            width: fit-content; 
            min-width: 100px;
            border-radius: 10px;
            border-style: solid;
            border-width: 3px;
            margin-bottom: 5px;
            margin-right: 5px;
            cursor: pointer;
            }
        .login-recommendations-categories-categories-subcategory-selected{
            text-align: center;
            font-size: 15px;
            font-family: montserrat;
            color: rgb(34, 34, 34);
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 700;
            width: fit-content; 
            min-width: 100px;
            border-radius: 10px;
            border-style: solid;
            border-width: 3px;
            margin-bottom: 5px;
            margin-right: 5px;
            cursor: pointer;
                }
            .login-recommendations-categories-categories-subcategory:hover{
                background-color: white;
                color: rgb(34, 34, 34);
                cursor: pointer;
                }
        .login-recommendations-categories-categories-childcategory-container{
        text-align: left;
        display: block;
    }
    .login-recommendations-categories-categories-childcategory{
        text-align: center;
            font-size: 12px;
            font-family: montserrat;
            color: rgb(34, 34, 34);
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            width: fit-content; 
            min-width: 70px;
            border-radius: 10px;
            border-style: solid;
            border-width: 3px;
            margin-bottom: 5px;
            margin-right: 1px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
}

.login-recommendations-categories-categories-display-container{
margin-top: 0;
}

.login-recommendations-categories-categories-buttons-container{
margin-bottom: 50px;
}
.login-recommendations-categories-categories-button{
    text-align: center;
            font-size: 25px;
            font-family: montserrat;
            color: rgb(34, 34, 34);
            background-color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            width: fit-content; 
            min-width: 70px;
            border-radius: 10px;
            border-style: solid;
            border-width: 3px;
            margin-bottom: 5px;
            margin-right: 1px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
}
.login-recommendations-categories-categories-button:hover{
    background-color: rgb(34, 34, 34);
    color: white;
    cursor: pointer;   
}


.login-recommendations-categories-parentcategories-overview-add-remove-header{
    font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-bottom: 30px;
        margin-top: 60px;
}

.login-recommendations-categories-parentcategories-overview-add-button{
    text-align: center;
    font-size: 12px;
    font-family: montserrat;
    color: rgb(34, 34, 34);
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    width: fit-content; 
    min-width: 70px;
    border-radius: 10px;
    border-style: solid;
    border-width: 3px;
    margin-bottom: 5px;
    margin-right: 1px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.login-recommendations-categories-subcategories-overview-add-remove-header{
    font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-bottom: 30px;
        margin-top: 60px;
}


.login-recommendations-categories-subcategories-overview-add-remove-header{
    font-size: 30px;
        font-family: montserrat;
        color: white;
        text-align: center;
        letter-spacing: 10px;
        margin-bottom: 30px;
        margin-top: 60px;
}

.login-recommendations-categories-subcategories-overview-add-button{
    text-align: center;
    font-size: 12px;
    font-family: montserrat;
    color: rgb(34, 34, 34);
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 600;
    width: fit-content; 
    min-width: 70px;
    border-radius: 10px;
    border-style: solid;
    border-width: 3px;
    margin-bottom: 5px;
    margin-right: 1px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}


.login-recommendations-categories-categories-childcategory-add{
    text-align: center;
        font-size: 12px;
        font-family: montserrat;
        color: white;
        background-color: rgb(34, 34, 34);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 600;
        width: fit-content; 
        min-width: 70px;
        border-radius: 10px;
        border-style: solid;
        border-width: 3px;
        margin-bottom: 5px;
        margin-right: 1px;
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        border-color: white;
}