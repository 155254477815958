.design-one-page-one-body{
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: white;
    position: fixed;
    top:0;
}


.design-one-page-one-left-body{
    width: 30%;
    vertical-align: top;
    height: 100vh;
    background-color: white;
    display: inline-block;
    position: relative;
    overflow-y: auto;
    z-index: 2;
}

.design-one-page-one-right-body{
    width: 70%;
    vertical-align: top;
    height: 100vh;
    background-color: #f7f7f7;
    display: inline-block;
    position: relative;
    z-index: 2;
}

.design-one-page-one-left-cityexplorer{
    width: 95%;
    margin-left: 2.5%;
    margin-right: 0;
    margin-top: 100px;
    padding-top: 75px;
    height: 50px;
    position: relative;
    vertical-align: top;
    text-align: center;
}

.design-one-page-one-left-cityexplorer-logo{
    width: 40px;
    height: 40px;
    margin-top: 5px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 15%;
}
.design-one-page-one-left-cityexplorer-logo-image{
    width: 100%;
    height: auto;
    }

.design-one-page-one-left-cityexplorer-mapsuntold{
    height: 40px;
    margin-top: 5px;
    padding-left: 3px;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-family: montserrat;
    font-size: 14px;
    font-weight: 500;
    color: lightgray;
    max-width: 25%;


}
.design-one-page-one-left-cityexplorer-mapsuntold-text{
    margin-top: 20px;
    transform: translateY(-50%);
}
.design-one-page-one-left-cityexplorer-line{
    position: relative;
    display: inline-block;
    width: 2.5px;
    background-color: lightgray;
    height: 35px;
    margin-top: 7px;
    border-top: 3px;
    border-radius: 1.5px;
}
.design-one-page-one-left-cityexplorer-city{
    height: 40px;
    max-width: 50%;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}
.design-one-page-one-left-cityexplorer-city-text{
    margin-top: 20px;
    transform: translateY(-50%);
    font-family: montserrat;
    font-size: 14px;
    color: #264f73;
    font-weight: 600;
}
.design-one-page-one-left-header{
    font-family: montserrat;
    font-size: 30px;
    font-weight: 600;
    padding: 20px 50px;
    line-height: 40px;
}


.design-one-page-one-left-subheader{
    font-family: montserrat;
    font-size: 14px;
    font-weight: 400;
    color: grey;
    font-size: 15px;
    padding: 0 50px 85px;
    line-height: 22.5px;
}

@keyframes fadeInContent {
    0% {
        opacity:0;
            }
    30% {
        opacity:0;
      }
    100% {
        opacity:1;
    }
  }





  .design-one-page-one-right-container{
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .design-one-page-one-right-standardtext{
    text-align: center;
    font-family: montserrat;
    font-size: 27.5px;
    font-weight: 700;
    white-space: pre-line;
  }
  .design-one-page-one-right-text{
    text-align: center;
    font-family: montserrat;
    font-size: 27.5px;
    font-weight: 700;
    animation: slideshowtext 4s ease-in-out infinite;
}


.design-one-page-one-right-picture-0{
    height: 30vh;
    width: 30vh;
    margin-top: -50px;
    margin-left: -15vh;
    display: block;
    animation: slideshowpic0 4s ease-in-out infinite;
    
}
.design-one-page-one-right-picture-1{
    height: 30vh;
    width: 30vh;
    margin-top: -30vh;
    margin-left: calc(100% - 15vh);
    display: block;
    animation: slideshowpic1 4s ease-in-out infinite;
}
.design-one-page-one-right-picture-2{
    height: 30vh;
    width: 30vh;
    margin-top: -10vh;
    margin-left: calc(25% - 15vh );    
    display: block;
    animation: slideshowpic2 4s ease-in-out infinite;
}
.design-one-page-one-right-picture-3{
    height: 30vh;
    width: 30vh;
    margin-top: -30vh;
    margin-left: calc(75% - 15vh);    
    display: block;
    animation: slideshowpic3 4s ease-in-out infinite;
}
.design-one-page-one-right-picture-4{
    height: 30vh;
    width: 30vh;
    margin-top: -15vh;
    display: block;
    margin-left: calc(50% - 15vh);    
    animation: slideshowpic4 4s ease-in-out infinite;
  }
  @keyframes slideshowpic0 {
    0% {
        transform:translateY(40%);
        opacity: 0;
    }
    12.50% {
        transform:translateY(0);
        opacity: 100;
      }
    85% {
      transform:translateY(0);
      opacity: 100;
    }
    100% {
        transform:translateY(-40%);
        opacity: 0;
    }
  }
  @keyframes slideshowpic1 {
    0% {
        transform:translateY(40%);
        opacity: 0;
    }
    12.5% {
        transform:translateY(0);
        opacity: 100;
      }
    80% {
      transform:translateY(0);
      opacity: 100;
    }
    100% {
        transform:translateY(-40%);
        opacity: 0;
    }
  }
  @keyframes slideshowpic2 {
    0% {
        transform:translateY(40%);
        opacity: 0;
    }
    20% {
        transform:translateY(0);
        opacity: 100;
      }
    90% {
      transform:translateY(0);
      opacity: 100;
    }
    100% {
        transform:translateY(-40%);
        opacity: 0;
    }
  }
  @keyframes slideshowpic3 {
    0% {
        transform:translateY(40%);
        opacity: 0;
    }
    15% {
        transform:translateY(0);
        opacity: 100;
      }
    85% {
      transform:translateY(0);
      opacity: 100;
    }
    100% {
        transform:translateY(-40%);
        opacity: 0;
    }
  }
  @keyframes slideshowpic4 {
    0% {
        transform:translateY(40%);
        opacity: 0;
    }
    30% {
        transform:translateY(0);
        opacity: 100;
      }
    90% {
      transform:translateY(0);
      opacity: 100;
    }
    100% {
        transform:translateY(-40%);
        opacity: 0;
    }
  }
  @keyframes slideshowtext {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 100;
      }
    95% {
      opacity: 100;
    }
    100% {
        opacity: 0;
    }
  }






  ::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }


  @media screen and (max-width: 1024px) {

    .design-one-page-one-body{
        width: 100%;
        background-color: white;
        position: fixed;
        overflow-y: scroll;
        top:0;
    }

    .design-one-page-one-left-body{
        width: 100%;
        height: fit-content;
        display: block;
        padding-bottom: 50px;
    }

    .design-one-page-one-left-subheader{
      padding: 0 50px 0;
  }

    .design-one-page-one-right-body{
        width: 100%;
        display: block;
        min-height: 70vh;
        height: auto;
    }

  }