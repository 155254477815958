.design-four-page-five-body{
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: white;
    position: fixed;
    top:0;
}


    .design-four-page-five-left-body{
        width: 30%;
        vertical-align: top;
        height: 100vh;
        background-color: white;
        display: inline-block;
        position: relative;
        z-index: 2;
    }
    
    .design-four-page-five-right-body{
        width: 70%;
        vertical-align: top;
        height: 100vh;
        background-color: #f7f7f7;
        display: inline-block;
        position: relative;
        z-index: 2;
        overflow-y: scroll;
    }
    
    .design-four-page-five-left-cityexplorer{
        width: 95%;
        margin-left: 2.5%;
        margin-right: 0;
        margin-top: 100px;
        padding-top: 75px;
        height: 50px;
        position: relative;
        vertical-align: top;
        text-align: center;
    }
    
    .design-four-page-five-left-cityexplorer-logo{
        width: 40px;
        height: 40px;
        margin-top: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        max-width: 15%;
    }
    .design-four-page-five-left-cityexplorer-logo-image{
        width: 100%;
        height: auto;
        }
    
    .design-four-page-five-left-cityexplorer-mapsuntold{
        height: 40px;
        margin-top: 5px;
        padding-left: 3px;
        padding-right: 10px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 14px;
        font-weight: 500;
        color: lightgray;
        max-width: 25%;
    
    
    }
    .design-four-page-five-left-cityexplorer-mapsuntold-text{
        margin-top: 20px;
        transform: translateY(-50%);
    }
    .design-four-page-five-left-cityexplorer-line{
        position: relative;
        display: inline-block;
        width: 2.5px;
        background-color: lightgray;
        height: 35px;
        margin-top: 7px;
        border-top: 3px;
        border-radius: 1.5px;
    }
    .design-four-page-five-left-cityexplorer-city{
        height: 40px;
        max-width: 50%;
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .design-four-page-five-left-cityexplorer-city-text{
        margin-top: 20px;
        transform: translateY(-50%);
        font-family: montserrat;
        font-size: 14px;
        color: #264f73;
        font-weight: 600;
    }
    .design-four-page-five-left-header{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 600;
        padding: 20px 50px;
        line-height: 40px;
    }
    
    
    .design-four-page-five-left-subheader{
        font-family: montserrat;
        font-size: 14px;
        font-weight: 400;
        color: grey;
        font-size: 15px;
        padding: 0 50px;
        line-height: 22.5px;
    }
    
    .design-four-page-five-wishlist-no-items{
        font-family: montserrat;
        font-size: 17.5px;
        font-weight: 400;
        margin-top: 15px;
        color: gray;
    }

    .design-four-page-five-wishlist-container{
        margin-top: 120px;
        width: 80%;
        margin-left: 10%;
        }
        .design-four-page-five-wishlist-item-container{
            margin-bottom: 10px;
            width: 100%;
            height: 65px;
            background-color: white;

            }
            .design-four-page-five-wishlist-item-img{
                width: 65px;
                height: 65px;
                display: inline-block;
                vertical-align: top;
                }
            .design-four-page-five-wishlist-item-img-notfound{
                width: 65px;
                height: 65px;
                display: inline-block;
                vertical-align: top;
            }
    .design-four-page-five-wishlist-item-title-cat-container{
        width: calc(100% - 330px);
        height: 65px;
        display: inline-block;
        vertical-align: top;
    }
    .design-four-page-five-wishlist-header{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .design-four-page-five-wishlist-item-title{
        font-family: montserrat;
        font-size: 15px;
        font-weight: 600;
        margin-top: 15px;
        margin-left: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .design-four-page-five-wishlist-item-category{
        font-family: montserrat;
        font-size: 11px;
        font-weight: 500;
        margin-top: 5px;
        margin-left: 20px;
    }
    .design-four-page-five-wishlist-item-route-icon{
        font-size: 15px;
        margin-right: 7.5px;
    }
    .design-four-page-five-wishlist-item-route{
        margin-top: 22px;
        width: 75px;
        color: #264f73;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 15px;
        font-weight: 500;
        text-decoration: underline;
    }
    .design-four-page-five-wishlist-item-website-icon{
        font-size: 15px;
        margin-right: 7.5px;
    }
    .design-four-page-five-wishlist-item-website{
        margin-top: 22px;
        width: 90px;
        color: #264f73;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 15px;
        font-weight: 500;
        text-decoration: underline;
    }
    .design-four-page-five-wishlist-item-bin-icon{
        font-size: 15px;
        margin-right: 7.5px;
    }
    .design-four-page-five-wishlist-item-bin{
        margin-top: 22px;
        width: 100px;
        color: #264f73;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 15px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }

    .design-four-page-five-wishlist-mailheader{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 500;
        margin-top: 25px;
    }
    .design-four-page-five-wishlist-mailsubheader{
        font-family: montserrat;
        font-size: 14px;
        font-weight: 500;
        color: gray;
        margin-top: 5px;
    }
    .design-four-page-five-wishlist-inputmail{
        font-size: 14px;
        font-family: montserrat;
        font-weight: 600;
        height: 50px;
        padding-left: 10px;
        width: 100%;
        border-style: none;
        border-width: 0;
        cursor: text;
       
    }
    .design-four-page-five-wishlist-mail-button{
        margin-top: 10px;
        font-size: 14px;
        font-family: montserrat;
        font-weight: 600;
        border-radius: 0;
        height: 28px;
        width: 120px;
        background-color: #264f73;
        color: white;
        text-decoration: none;
        padding-top: 10px;
        text-align: center;
        cursor: pointer;
    }

    .design-four-page-five-mailsend-container{
        margin-left: calc(50vw - 37.5px);
        margin-top: calc(50vh - 37.5px);
        position: fixed;
        width: 75px;
        height: 50px;
        background-color: white;
        text-align: center;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 10;
        font-size: 0;
        border-style: solid;
        border-color: black;
        border-width: 4px;
        border-radius: 8px;
        animation: mailsend 3s ease-in-out;
    }


    @keyframes mailsend {
      0% {  
          opacity: 0;
        width: 0;
        font-size: 0;
        height: 0;
      }
      5% {
            opacity: 1;
            width: 100px;
            font-size: 10px;
            height: 50px;
      }
      42.5% {
        opacity: 1;
        width: 100px;
        font-size: 10px;
  }
      50% {
        opacity: 1;
        width: 75px;
        font-size: 0;
    } 
    60% {
        opacity: 1;
        width: 75px;
        font-size: 0;
        transform: translateX(0);
    } 
    70% {
        transform: translateX(100vw);
    } 
    100% {
        transform: translateX(100vw);
        opacity: 0;
    } 
    }

    .design-four-page-five-mailsend-left{
        width: 2px;
        background-color: black;
        height: 45px;
        transform: rotate(-60deg);
        margin-top: -8px;
        margin-left: 18px;
        animation: mailsendstripe 3s ease-in-out;
    }
    .design-four-page-five-mailsend-right{
        width: 2px;
        background-color: black;
        height: 45px;
        transform: rotate(60deg);
        margin-top: -45px;
        margin-left: 56px;
        animation: mailsendstripe 3s ease-in-out;
    }
    @keyframes mailsendstripe {
        0% {  opacity: 0;
        }
        5% {  opacity: 0;
        }
        45% {  opacity: 0;
        }
        52.5% {opacity: 1;
        } 
        100% {
        opacity: 1;
        } 
    }

    .design-four-page-five-mailsend-text{
        text-align: center;
        opacity: 1;
        top: 0;
        left: 0;
        z-index: 10;
        color:black;
        font-size: 0;
        margin-top: -22.5px;
        font-family: montserrat;
        font-weight: 500;
        animation: mailsendtext 3s ease-in-out;
    }
    @keyframes mailsendtext {
        0% {  
            font-size: 0;
        }
        5% {  
            font-size: 14px;
        }
        42.5% {  
            font-size: 14px;
        }
        45% {  
            font-size: 0;
        }
        100% {  
            font-size: 0;
        }
    }


    .design-four-page-five-mailsend-background{
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 0.3;
        position: fixed;
        top: 0;
        left: 0;
        animation: mailsendbackground 3s ease-in-out;
        z-index: 5;
    }
    @keyframes mailsendbackground {
        0% {  
            opacity: 0;
        }
        5% {  
            opacity: 0.3;
        }
        35% {  
            opacity: 0.3;
            transform: translate(0,0);   
            width: 100vw;
            height: 100vh;         
            border-radius: 0;
        }
        44% {  
            opacity: 0;
            transform: translate(50vw, 50vh);
            width: 0;
            height: 0;
            border-radius: 50%;
        }
        100% {  
            opacity: 0;
        }
    }

    

@media screen and (max-width: 1024px) {
    .design-four-page-five-body{
        width: 100%;
        background-color: white;
        position: fixed;
        overflow-y: scroll;
        top:0;
    }
    .design-four-page-five-left-body{
        width: 100%;
        height: fit-content;
        display: block;
        padding-bottom: 50px;
    }

    .design-four-page-five-left-subheader{
        padding: 0 50px 0;
    }

    .design-four-page-five-right-body{
        width: 100%;
        display: block;
        height: auto;
    }
    .design-four-page-five-lds-dual-ring:after {
        margin-left: calc(40vw - 37.5px);
    }
    .design-four-page-five-right-first-container{
        padding-top: 0;
    }
    .design-four-page-five-wishlist-container{
        padding-top: 25px;
        margin-top: 0;
    }

    .design-four-page-five-wishlist-item-container{
        height: 130px;
    }

    .design-four-page-five-wishlist-item-title-cat-container{
        width: calc(100% - 65px);
    }
    .design-four-page-five-wishlist-item-website{
        width: fit-content;
        padding-left: 15px;
        font-size: 12px;
    }
    .design-four-page-five-wishlist-item-route{
        width: fit-content;
        padding-left: 15px;
        font-size: 12px;
    }
    .design-four-page-five-wishlist-item-bin{
        width: fit-content;
        padding-left: 15px;
        font-size: 12px;
    }
  }