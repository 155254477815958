.login-styles-container {
    padding-top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: rgb(34, 34, 34);
   }
   .login-styles-text {
       position: absolute;
       width: 100%;
       height: 250px;
       top: 50%;
       margin-top: -125px;    
       font-size: 30px;
       font-family: montserrat;
       color: white;
       text-align: center;
      }