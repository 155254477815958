.design-three-page-two-body{
    width: 100%;
    height: 100vh;
    margin: 0;
    background-color: white;
    position: fixed;
    top:0;
}

    .design-three-page-two-left-body{
        width: 30%;
        vertical-align: top;
        height: 100vh;
        background-color: white;
        display: inline-block;
        position: relative;
        overflow-y: auto;
        z-index: 2;
    }
    
    .design-three-page-two-right-body{
        width: 70%;
        vertical-align: top;
        height: 100vh;
        background-color: #f7f7f7;
        display: inline-block;
        position: relative;
        z-index: 2;
        overflow-y: scroll;
    }
    
    .design-three-page-two-left-cityexplorer{
        width: 95%;
        margin-left: 2.5%;
        margin-right: 0;
        margin-top: 100px;
        padding-top: 75px;
        height: 50px;
        position: relative;
        vertical-align: top;
        text-align: center;
    }
    
    .design-three-page-two-left-cityexplorer-logo{
        width: 40px;
        height: 40px;
        margin-top: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        max-width: 15%;
    }
    .design-three-page-two-left-cityexplorer-logo-image{
        width: 100%;
        height: auto;
        }
    
    .design-three-page-two-left-cityexplorer-mapsuntold{
        height: 40px;
        margin-top: 5px;
        padding-left: 3px;
        padding-right: 10px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-family: montserrat;
        font-size: 14px;
        font-weight: 500;
        color: lightgray;
        max-width: 25%;
    
    
    }
    .design-three-page-two-left-cityexplorer-mapsuntold-text{
        margin-top: 20px;
        transform: translateY(-50%);
    }
    .design-three-page-two-left-cityexplorer-line{
        position: relative;
        display: inline-block;
        width: 2.5px;
        background-color: lightgray;
        height: 35px;
        margin-top: 7px;
        border-top: 3px;
        border-radius: 1.5px;
    }
    .design-three-page-two-left-cityexplorer-city{
        height: 40px;
        max-width: 50%;
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    }
    .design-three-page-two-left-cityexplorer-city-text{
        margin-top: 20px;
        transform: translateY(-50%);
        font-family: montserrat;
        font-size: 14px;
        color: #264f73;
        font-weight: 600;
    }
    .design-three-page-two-left-header{
        font-family: montserrat;
        font-size: 30px;
        font-weight: 600;
        padding: 20px 50px;
        line-height: 40px;
    }
     
    .design-three-page-two-left-subheader{
        font-family: montserrat;
        font-size: 14px;
        font-weight: 400;
        color: grey;
        font-size: 15px;
        padding: 0 50px 85px;
        line-height: 22.5px;
    }

    .design-three-page-two-right-first-container{
        padding-top: 100px;
        font-size: 0;
    }

    .design-three-page-two-right-categories-container{
    position: relative;
    width: 90%;
    padding-left: 10%;
    margin-bottom: 30px;
    padding-bottom: 60px;
    margin-bottom: 150px;
    }

    .design-three-page-two-right-category-container{
        display: inline-block;
        vertical-align: top;
        font-size: 0;
    }
    .design-three-page-two-right-category-part{
        background-color: white;
        text-align: center;
        vertical-align: top;
        display: inline-block;
        border-style: solid;
        border-width: 1.5px;
        width: 200px;
        height: 200px;
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 8px;
        border-radius: 8px;
        border-color: gray;
        font-size: 14px;
        font-family: montserrat;
        font-weight: 500;
    }
        /* animation: tileanimation 0.3s ease-out ;
    }
    @keyframes tileanimation {
        0% {
            transform: scale(0);
            }
            60% {
                transform: scale(0);
                }
            100% {
                transform: scale(100%);
                }
        } */


    .design-three-page-two-right-category-image{
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100px;
        height: 100px;
        margin-left:50%;
        transform: translateX(-50%);  
        }

        .design-three-page-two-right-header{
        font-family: montserrat;
        font-size: 27.5px;
        font-weight: 600;

        }
        .design-three-page-two-right-subheader{
        margin-top: 5px;
        margin-bottom: 20px;
        font-family: montserrat;
        font-size: 15px;
        font-weight: 500;

    }
    

    @media screen and (max-width: 1024px) {

        .design-three-page-two-body{
            width: 100%;
            background-color: white;
            position: fixed;
            overflow-y: scroll;
            top:0;
        }
   
        .design-three-page-two-left-body{
            width: 100%;
            height: fit-content;
            display: block;
            padding-bottom: 50px;
        }

        .design-three-page-two-left-subheader{
            padding: 0 50px 0;
        }
    
        .design-three-page-two-right-body{
            width: 100%;
            display: block;
            height: auto;
        }


        .design-three-page-two-right-category-part{
            width: 140px;
            height: 140px;
            margin-left: 2px;
            margin-right: 2px;
            margin-bottom: 4px;
            border-radius: 4px;
            font-size: 12.5px;
        }
        .design-three-page-two-right-category-image{
            margin-top: 30px;
            margin-bottom: 10px;
            width: 70px;
            height: 70px;
            margin-left:50%;
            transform: translateX(-50%);  
            }
        
        .design-three-page-two-right-first-container{
        padding-top: 0;
    }
      }



      @media screen and (max-width: 768px) {
        .design-three-page-two-right-category-part{
            width: 100px;
            height: 100px;
            margin-left: 2px;
            margin-right: 2px;
            margin-bottom: 4px;
            border-radius: 4px;
            font-size: 10px;
        }
        .design-three-page-two-right-category-image{
            margin-top: 20px;
            margin-bottom: 10px;
            width: 50px;
            height: 50px;
            margin-left:50%;
            transform: translateX(-50%);  
            }
      }